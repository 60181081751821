<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <grants />
      </v-col>
      <v-col cols="4">
        <submit-grant />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Grants from "../components/Grants.vue";
import SubmitGrant from "../components/SubmitGrant.vue";
export default {
  components: { SubmitGrant, Grants },
};
</script>
