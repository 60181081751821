<template>
  <v-card elevation="2" class="pa-6 mt-10">
    <v-card-title>
      Submitted Grants
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="grants"
      :search="search"
      class="elevation-1"
    ></v-data-table>
  </v-card>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "grantID" },
        { text: "Grant Title", value: "grantName" },
        { text: "Granting Body", value: "grantingBody" },
        { text: "Grant Type", value: "grantType" },
        { text: "Grant Deadline", value: "grantDeadline" },
      ],
      grants: [],
    };
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    getEvents() {
      axios.get("http://localhost:8091/api/v1/grants").then((response) => {
        this.grants = response.data;
        console.log(this.events);
      });
    },
  },
};
</script>
