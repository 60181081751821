<template>
  <v-card elevation="2" class="pa-6 mt-10">
    <h2 class="font-weight-regular mb-4">Submit a new grant</h2>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-select
        v-model="grantType"
        :items="grantTypes"
        label="Grant Type"
        dense
        outlined
        required
        :rules="textRules"
      ></v-select>
      <v-text-field
        v-model="grantName"
        label="Grant Name"
        dense
        outlined
        required
        :rules="textRules"
      ></v-text-field>
      <v-autocomplete
        v-model="grantBody"
        :items="grantingBodies"
        outlined
        dense
        label="Granting Body"
        required
        :rules="textRules"
      ></v-autocomplete>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-combobox
            v-model="grantDate"
            readonly
            v-bind="attrs"
            v-on="on"
            prepend-icon="mdi-calendar"
            dense
            label="Grant Deadline"
            outlined
            required
            :rules="textRules"
          ></v-combobox>
        </template>
        <v-date-picker v-model="grantDate" no-title scrollable>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(grantDate)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-file-input
        v-model="fileName"
        truncate-length="15"
        dense
        label="Upload grant proposal"
        outlined
        required
        :rules="textRules"
      ></v-file-input>
      <v-btn
        class="ma-2"
        outlined
        color="indigo"
        @click="submit"
        :disabled="!valid"
      >
        Submit grant
      </v-btn>
      <v-btn class="ma-2" outlined color="red" @click="reset">
        Clear
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      grantTypes: ["Research", "Travel", "Instrument"],
      grantingBodies: [
        "National Research Council",
        "National Science Foundation",
        "Ministry of Science",
      ],
      menu: false,
      valid: false,
      textRules: [(v) => !!v || "This field is required"],
      grantType: "",
      grantName: "",
      grantBody: "",
      grantDate: "",
      filePath: "",
    };
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    submit() {
      this.$refs.form.validate();
      this.onSubmit();
    },
    onSubmit() {
      axios
        .post("http://localhost:8091/api/v1/grants", {
          grantType: this.grantType,
          grantName: this.grantName,
          grantingBody: this.grantBody,
          grantDeadline: this.grantDate,
          filePath: this.grantName,
          feedback: "Not available",
          grantStatus: "0",
        })
        .then((response) => {
          console.log(response);
          this.$refs.form.reset();
        });
    },
  },
};
</script>
